<app-topbar [home]="home"></app-topbar>
<div role="main" class="main" style=" background-color: #FAFAFA !important;">
  <section class="section section-concept section-no-border section-light pt-lg-5 m-0" id="section-concept" style=" ">
    <div class="container">
      <div class="row align-items-center pt-lg-5">
        <div class="col-lg-8 offset-lg-2 col-sm-12 mt-lg-3 mb-0 caption text-center">
          <h1 class="font-weight-bold text-15 line-height-2 mb-lg-3 appear-animation text-white animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            Building the future
          </h1>
          <p class="custom-font-size-1 appear-animation text-white text-5 animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750" style="color: #fff !important;">
            Rehabilitation of the Nigerian economy <br>by building sustainable business initiatives for the future
          </p>
          <p class="store-buttons mt-lg-5">
            <a (click)="gotodiv('about')" class="btn btn-success btn-blue bg-blue btn-rounded btn-modern btn-px-5 text-3 appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="300" target="_blank">
              Learn More
            </a>
          </p>

        </div>

      </div>

    </div>

  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept2">
    <div class="container">
      <div class="row align-items-center">

        <div class="col-sm-12 col-lg-5 mb-lg-5 mb-md-0 about">
          <img src="assets/img/controls/above-focus.svg"  class="img-fluid mb-5"/>
          <h1 class="font-weight-bold text-9 text-left text-blue mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">Our Focus</h1>
          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">

            <div class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              <!-- Our focus is to be instrumental in the rehabilitation of the Nigerian economy by building sustainable business initiatives for the future. -->
              <p>Kunoch aims to leverage on its successful investment portfolio to incubate valuable ventures in Agriculture, Education, 
Hospitality, Finance, Information Communication Technology (ICT), and transform to an international conglomerate 
based in Nigeria.</p>
<p>Kunoch strives to maximize returns on its short term investments as well as long-term returns by pursing investment 
opportunities in select sectors in Nigeria and MEA for the benefit of its shareholders and the Nigerian economy and 
with special emphasis on strengthening the South / South West underdeveloped areas of Nigeria and transforming 
them to new economical hubs.</p>
<p>Our philosophy is to create value in everything we do, in a transparent, socially responsible and fully compliant with 
Nigerian and International laws and blue prints of corporate governance.</p>
<p>Kunoch aims to attract and retain a highly competent and knowledgeable team of employees, with the highest levels of 
responsible corporate citizenship through following rules, laws, and regulations of Nigeria and all the countries in which 
it will operate. </p>
            </div>
          </div>
          <img src="assets/img/controls/below-focus.svg"  class="img-fluid mt-5"/>
        </div>
        <div class="col-sm-12 col-lg-7 mb-md-0 ">
          <img src="assets/img/controls/focus-right.png" class="img-fluid imgsvg appear-animation animated fadeInLeftShorter appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
      </div>
    </div>
  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept3">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-12 col-lg-7 mb-md-0 ">
          <img src="assets/img/controls/values-left.png" class="img-fluid imgsvg appear-animation animated fadeInRightShorter appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
        <div class="col-sm-12 col-lg-5 mb-lg-5 mb-md-0">
          <img src="assets/img/controls/below-focus.svg"  class="img-fluid mb-5 right" style="float: right"/>

          <h1 class="font-weight-bold text-9 text-left text-blue mt-5 mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">Our Values</h1>

          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">

            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              <b>Responsibility</b> - Responsibility in our relationships and business practices
              <br><br>
              <b>Fairness</b> - Our processes and structure focus on inclusivity and respect
              <br><br>
              <b>Wealth creation</b> - Our business Is driven by passion and sustainability for the future
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="about">


    <div class="container">
      <div class="row align-items-center">

        <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about" id="">
          <h1 class="font-weight-bold text-9 text-left text-blue mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">About Kunoch</h1>


          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">

            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              Kunoch Limited is a holding company established and registered on the 8th of August 1991 (Registered No 170032).
              <br><br>
              Kunoch strives to maximize returns on its short term investments as well as long-term returns by pursing investment opportunities in select sectors in Nigeria for the benefit of its shareholders and the Nigerian economy and with special emphasis on strengthening the South / South West underdeveloped areas of Nigeria and transforming them to new economical hubs.
              <br><br>
              Our philosophy is to create value in everything we do in a transparent, socially responsible and fully compliant with Nigerian and International laws, and the blue prints of corporate governance.	</p>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 mb-md-0 ">
          <img src="assets/img/controls/about.png" class="img-fluid imgsvg appear-animation animated fadeInRightShorter appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="container pt-4 bg-blue" id="subsidiary">
    <div class="row text-center pt-4 mt-5">
      <div class="col">
        <h2 class="word-rotator slide text-white font-weight-bold text-8 mb-2">
          <span>Subsidiaries</span>
        </h2>
      </div>
    </div>

    <div class="row text-center mt-5 pb-5 mb-5">
      <div class="col-md-3 offset-lg-4">
        <a href="https://janetdoziefoundation.org/" title="Links to Janet Dozie Foundation's Website" target="_blank">
          <img class="img-fluid jdf" src="assets/img/controls/logo.png" alt="">
        </a>
      </div> -->
<!--      <div class="owl-carousel owl-theme mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 7}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">-->

<!--        -->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/ibm.svg" alt="">-->
<!--        </div>-->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/oracle.svg" alt="">-->
<!--        </div>-->
<!--&lt;!&ndash;        <div>&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="img-fluid" src="assets/img/control/cisco.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div>&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="img-fluid" src="assets/img/control/harvard.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/algo.svg" alt="">-->
<!--        </div>-->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/mosh.png" alt="">-->
<!--        </div>-->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/mongo.svg" alt="">-->
<!--        </div>-->
<!--        <div>-->
<!--          <img class="img-fluid" src="assets/img/control/traversy.svg" alt="">-->
<!--        </div>-->
<!--      </div>-->
    <!-- </div>

  </div> -->
</div>
<app-bottombar [home]="home"></app-bottombar>
