import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  constructor() { }
  @Input() home: boolean;
  ngOnInit(): void {
  }

  gotodiv(id) {
    const element = document.getElementById(id);
    if (element)  { element.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
  }


}
