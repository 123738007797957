import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  home = true;
  constructor() { }

  ngOnInit(): void {
  }
  gotodiv(id) {
    const element = document.getElementById(id);
    if (element)  { element.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
  }

}
