import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ConnectService} from "../shared/services/connect.service";
import {Router} from "@angular/router";
import {EmailValidators} from "./email.validators";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  form;
  formed;
  clicked;
  status;
  response;
  data;
  constructor(fb: FormBuilder, private connectService: ConnectService,  private router: Router) {
    this.formed = fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      query: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      message: ['', [
        Validators.required,
        Validators.minLength(6)]]
    });
  };

  ngOnInit() {
  }
  connect() {
    this.clicked = true;
    console.log(this.formed.value);
    this.connectService.connect(this.formed.value).subscribe(
      data => {
        this.data = data;
        this.status = this.data.status;
        this.response = this.data.message;
        console.log(this.response);
        this.message.reset();
        this.clicked = false;
        this.formed.reset();
      },
      (error: Response) => {
        console.log(error);
        this.clicked = false;
      }
    );
  }

  get name() {
    return this.formed.get('name');
  }
  get email() {
    return this.formed.get('email');
  }
  get query() {
    return this.formed.get('query');
  }
  get message() {
    return this.formed.get('message');
  }
}
