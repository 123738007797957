<app-topbar></app-topbar>

<div role="main" class="main" style=" background-color: #FAFAFA !important;">
<!--  <section class="section sect section-concept sect section-no-border section-white pt-5 m-0" id="program-offering">-->
<!--    <div class="container">-->
<!--      <h5 class="font-weight-semibold text-9 mt-lg-5 pt-lg-5 text-left text-blue mb-2 appear-animation animated appear-animation-visible how-title" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">-->
<!--        Contact</h5>-->

<!--      <div class="row align-items-center counters">-->
<!--        <div class="col-sm-12 col-lg-6 counter mb-md-0 mt-5">-->


<!--          <div class="card bg-color-pink border-0 mb-2">-->
<!--            <div class="card-body row align-items-center">-->
<!--              <div class="col-lg-3 text-left">-->
<!--                <i class="icon visit"></i>-->
<!--              </div>-->
<!--              <div class="col-lg-9">-->
<!--                <h4 class="card-title mb-1 text-4  text-left">Visit Us</h4>-->
<!--                <p class="card-text text-left">-->
<!--                  Owerri, Imo State Nigeria-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->
<!--          <div class="card bg-color-yellow border-0 mb-2">-->
<!--            <div class="card-body row align-items-center">-->
<!--              <div class="col-lg-3 text-left">-->
<!--                <i class="icon call"></i>-->
<!--              </div>-->
<!--              <div class="col-lg-9">-->
<!--                <h4 class="card-title mb-1 text-4 text-white  text-left">Call Us</h4>-->
<!--                <p class="card-text text-left text-white">-->
<!--                  +234 (0) 80 3666 6619-->
<!--                  <br>-->
<!--                  +234 (0) 81 6270 2289-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->
<!--          <div class="card bg-color-blue border-0 mb-2">-->
<!--            <div class="card-body row align-items-center">-->
<!--              <div class="col-lg-3 text-left">-->
<!--                <i class="icon email"></i>-->
<!--              </div>-->
<!--              <div class="col-lg-9">-->
<!--                <h4 class="card-title mb-1 text-4 text-white  text-left">Email Us</h4>-->
<!--                <p class="card-text text-left text-white">-->
<!--                  info@kunochtech.com-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->



<!--        </div>-->
<!--        <div class="col-sm-12 col-lg-6 counter mb-md-0 mt-lg-5">-->

<!--          <div class="appear-animation text-left board animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">-->
<!--            <p class="alert" [class.alert-danger]="!status" [class.alert-success]="status" *ngIf="response">{{response}}</p>-->

<!--            <form id="contactForm"  [formGroup]="formed" (ngSubmit)="connect()" class="contact-form p-4 rounded bg-color-white">-->
<!--              <div class="p-2">-->
<!--                <p class="font-weight-semibold text-4 text-left text-dark mb-2 appear-animation animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">-->
<!--                  Get In Touch</p>-->

<!--                <div class="form-row">-->
<!--                  <div class="form-group col-lg-12">-->
<!--                    <input type="text" value="" formControlName="name" placeholder="Your Name" data-msg-required="Please enter your name." maxlength="100" class="form-control" name="name" id="name" required="">-->
<!--                    <div *ngIf="name.touched && name.invalid" class="alert alert-danger error">-->
<!--                      <div *ngIf="name.errors.required">Please enter your name.</div>-->
<!--                      <div *ngIf="name.errors.minlength">Name should be minimum of 3 characters</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="form-group col-lg-12">-->
<!--                    <input type="email" formControlName="email" value="" placeholder="Your Email" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control" name="email" id="email" required="">-->
<!--                    <div *ngIf="email.invalid && (email.touched || email.dirty)" class="alert alert-danger error">-->
<!--                      <div *ngIf="email.errors.required">Email is required</div>-->
<!--                      <div *ngIf="email.errors.cannotContainSpace">Email cannot contain space</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-row">-->
<!--                  <div class="form-group col">-->
<!--                    <textarea maxlength="5000" placeholder="Message" data-msg-required="Please enter your message." rows="8" class="form-control" name="message"  formControlName="message" id="message" required=""></textarea>-->
<!--                    <div *ngIf="message.touched && message.invalid" class="alert alert-danger error">-->
<!--                      <div *ngIf="message.errors.required">Message is required</div>-->
<!--                      <div *ngIf="message.errors.minlength">Message should be minimum of 3 characters</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-row">-->
<!--                  <div class="form-group col mb-0">-->
<!--                    <button [disabled]="formed.invalid || clicked == true" class="btn btn-rounded bg-blue px-5 btn-primary btn-modern">Submit<span *ngIf="clicked == true">ting</span></button>-->
<!--                    <i *ngIf="clicked == true" class="icon loading"></i>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->


<!--          </div>-->
<!--        </div>-->


<!--        &lt;!&ndash;        </div>&ndash;&gt;-->

<!--      </div>-->



<!--    </div>-->

<!--  </section>-->



<!--  Real-->
  <section class="section sect section-concept sect section-no-border section-white pt-5 pb-5 m-0" id="program-offering">
    <div class="container">

    <h5 class="font-weight-semibold text-9 mt-lg-5 pt-lg-5 text-left text-black mb-2 appear-animation animated appear-animation-visible how-title" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">
      Contact Us
      </h5>
      <p class="text-blue">Interested in collaborating? Please fill the form below.</p>

      <div class="row d-flex counters">
        <div class="col-sm-12 col-lg-6 counter mb-md-0 mt-lg-5">

          <div class="appear-animation text-left board animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="alert" [class.alert-danger]="!status" [class.alert-success]="status" *ngIf="response">{{response}}</p>

            <form id="contactForm"  [formGroup]="formed" (ngSubmit)="connect()" class="contact-form p-4 rounded bg-color-white">
            <div class="p-2">
                <p class="font-weight-bold text-4 text-left text-dark mb-2 appear-animation animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">
                  Get In Touch
                </p>

                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <input type="text" value="" placeholder="Your Full Name" formControlName="name"  data-msg-required="Please enter your name." maxlength="100" class="form-control" name="name" id="name" required="">
                    <div *ngIf="name.touched && name.invalid" class="alert alert-danger error">
                      <div *ngIf="name.errors.required">Please enter your name</div>
                      <div *ngIf="name.errors.minLength">Name should be minimum of 3 characters</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <input type="email" formControlName="email"  value="" placeholder="Your Email id" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control" name="email" id="email" required="">
                    <div *ngIf="email.invalid && (email.touched || email.dirty)" class="alert alert-danger error">
                      <div *ngIf="email.errors.required">Email is required</div>
                      <div *ngIf="email.errors.cannotContainSpace">Email cannot contain space</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <input type="text" formControlName="query"  value="" placeholder="Query Related" data-msg-required="Please enter your name." maxlength="100" class="form-control" name="name" id="name" required="">
                    <div *ngIf="query.touched && query.invalid" class="alert alert-danger error">
                      <div *ngIf="query.errors.required">Query is required</div>
                      <div *ngIf="query.errors.minLength">Query should be minimum of 3 characters</div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col">
                    <textarea maxlength="5000" placeholder="Message" data-msg-required="Your message." rows="8" class="form-control" name="message" id="message" formControlName="message"  required=""></textarea>
                    <div *ngIf="message.touched && message.invalid" class="alert alert-danger error">
                      <div *ngIf="message.errors.required">Message is required</div>
                      <div *ngIf="message.errors.minLength">Message should be minimum of 3 characters</div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col mb-0">
                    <input type="submit" value="Submit" class="btn btn-blue px-5 btn-primary btn-modern text-white" data-loading-text="Loading...">
                  </div>
                </div>
              </div>
            </form>


          </div>
        </div>

        <div class="col-sm-8 col-lg-6 counter mb-md-0 mt-5">


          <div class="card bg-color-pink border-0 mb-2">
            <div class="card-body row align-items-center">
              <div class="col-lg-3 text-left">
                <i class="icon visit"></i>
              </div>
              <div class="col-lg-9">
                <h4 class="card-title mb-1 text-4  text-left">Visit Us</h4>
                <p class="card-text text-left">
                  14A, Oba Elegushi Rd, Ikoyi, Lagos
                </p>
              </div>
            </div>


          </div>
          <div class="card bg-color-yellow border-0 mb-2">
            <div class="card-body row align-items-center">
              <div class="col-lg-3 text-left">
                <i class="icon call"></i>
              </div>
              <div class="col-lg-9">
                <h4 class="card-title mb-1 text-4 text-left">Call Us</h4>
                <p class="card-text text-left">
                  01-454-5851
                  <br>
                </p>
              </div>
            </div>


          </div>
          <div class="card bg-color-blue border-0 mb-2">
            <div class="card-body row align-items-center">
              <div class="col-lg-3 text-left">
                <i class="icon email"></i>
              </div>
              <div class="col-lg-9">
                <h4 class="card-title mb-1 text-4  text-left">Email Us</h4>
                <p class="card-text text-left">
                  info@kunoch.com
                </p>
              </div>
            </div>


          </div>



        </div>
      </div>
    </div>

  </section>


</div>

<app-bottombar></app-bottombar>
