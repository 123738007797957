<footer>
  <div class="container">

    <div class="row py-5 my-4 border-top-dark">
      <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
        <h5 class="text-3 mb-3 text-center"><img src="assets/img/controls/logo.svg"></h5>

      </div>
      <!-- <div class="col-md-6 col-lg-1">
      </div> -->
      <div class="col-md-6 col-lg-2 mb-4 mb-lg-0">
        <h5 class="text-4 mb-3">Pages</h5>
        <p><a class="footer-links" (click)="gotodiv('about')" *ngIf="home">About</a></p>
        <p><a  href="/#about" class="footer-links" *ngIf="!home">About</a></p>
        <!-- <p><a class="footer-links" (click)="gotodiv('subsidiary')" *ngIf="home">Subsidiaries</a></p>
        <p><a  href="/#subsidiary" class="footer-links" *ngIf="!home">Subsidiaries</a></p> -->
        <p><a routerLink="/team" class="footer-links">Team</a></p>

      </div>
      <div class="col-md-6 col-lg-3 mb-4 mb-md-0">
        <div class="contact-details">
          <h5 class="text-4 mb-3">Reach Us</h5>
          <p style="color: #333333">14A, Oba Elegushi Street, Ikoyi, Lagos</p>
          <p><a href="mailto:  kelechi@kunoch.com"> info@kunoch.com</a></p>
          <p><a href="tel: 01-454-5851"> 01-454-5851</a></p>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 mb-4 mb-md-0">
        <div class="contact-details">
          <h5 class="text-4 mb-3">Philanthropy</h5>
          <p><a href="https://janetdoziefoundation.org/">Janet Dozie Foundation</a></p>
        </div>
      </div>

    </div>
  </div>

</footer>
<section class="section  section-concept sect section-no-border section-blue section-angled section-angled-reverse pt-3 m-0 last">
  <div class="container text-center last-footer">
    <p class="text-4  align-self-center text-white">Copyright © 2022 Kunoch. All Rights Reserved.</p>
  </div>
</section>
