<header id="header" class="header-gray " data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': false, 'stickyEnableOnMobile': true, 'stickyStartAt': 70, 'stickyChangeLogo': false, 'stickyHeaderContainerHeight': 70}">
  <div class="header-body border-top-0" style="padding: 10px 0 !important;">
    <div class="header-container container">
      <div class="header-row">
        <div class="header-column">
          <div class="header-row">
            <div class="header-logo appear-animation appear-animation-visible"  data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
              <a href="index.html" class="goto-top d-lg-block d-sm-none">
                <img alt="Porto" width="190" height="auto" data-sticky-width="82" data-sticky-height="36" data-sticky-top="0" src="assets/img/controls/logo.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="header-column justify-content-end">
          <div class="header-row">
            <div class="header-nav header-nav-links header-nav-light-text header-nav-dropdowns-dark">
              <div class="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-text-capitalize header-nav-main-text-size-2 header-nav-main-mobile-dark header-nav-main-effect-1 header-nav-main-sub-effect-1 appear-animation appear-animation-visible"  data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
                <nav class="collapse">
                  <ul class="nav nav-pills" id="mainNav">

                    <li class="dropdown">
                      <a class="dropdown-item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact : true}">

                        Home
                      </a>
                    </li>
                    <li class="dropdown" *ngIf="home">
                      <a class="dropdown-item" (click)="gotodiv('about')" >
<!--                        href="/home/#about"-->
                        About
                      </a>
                    </li>
                    <li class="dropdown" *ngIf="!home">
                      <a class="dropdown-item" href="/#about">
<!--                        href="/home/#about"-->
                        About
                      </a>
                    </li>
                    <!-- <li class="dropdown" *ngIf="home">
                      <a class="dropdown-item"  (click)="gotodiv('subsidiary')" >
                      href="/home/#subsidiary"
                        Subsidiaries
                      </a>
                    </li> -->
                    <li class="dropdown" *ngIf="!home">
                      <a class="dropdown-item"  href="/#subsidiary">
<!--                        href="/home/#subsidiary"-->
                        Subsidiaries
                      </a>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-item" routerLink="/team" routerLinkActive="active">
                        Team
                      </a>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-item" routerLink="/contact" routerLinkActive="active">
                        Contact us
                      </a>
                    </li>

                  </ul>
                </nav>
              </div>
<!--              <a class="btn btn-outline-light btn-rounded font-weight-semibold px-4 ml-3 d-sm-none appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}" href="#" target="_blank">Contact Us</a>-->
              <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav"><i class="fa fa-bars"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
