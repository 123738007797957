import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.css']
})
export class BottombarComponent implements OnInit {

  @Input() home: boolean;
  ngOnInit(): void {
  }

  gotodiv(id) {
    const element = document.getElementById(id);
    if (element)  { element.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
  }

}
