<app-topbar></app-topbar>
<div role="main" class="main" style=" background-color: #FAFAFA !important;">
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept">


    <div class="container">
      <!-- <div class="row mt-lg-5 pt-lg-5">
        <div class="col-sm-12 col-lg-3 mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-blue mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">Our Founders</h1>
        </div>
        <div class="col-sm-12 col-lg-9 mb-md-0 ">
          <div class="row ">

            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/pgd.jpeg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Pascal G. Dozie
                  </h2>
                  <h5>Chairman</h5>
                  <p>
                    Mr. Pascal Dozie is a philanthropist, investor and prominent entrepreneur. He is the founder and former Chairman/Chief Executive Officer of Diamond Bank PLC (LON: DBGA) now merged with Access Bank Plc. He was the founding Chairman of MTN Nigeria Limited where he led the board for 18 years. He is also a founding partner of Africa Capital Alliance, the first Nigerian private equity fund manager. He holds an M.Sc. in Business Administration from the City University, London and a B.Sc. in Economics from the London School of Economics.
                  </p>
                </div>
              </article>
            </div>
            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/chineye.jpg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Chineye Dozie
                  </h2>
                  <h5>Director</h5>
                  <p>
                    Mrs. Dozie is an astute business woman who sits on the board of several reputable companies and has worked at Nestle Nig. Plc. and African Development Consulting Group Ltd. She founded Elpina Associates Ltd, home appliances company based in Lagos.
                  </p>
                </div>
              </article>
            </div>

          </div>
        </div>
      </div> -->
      <div class="row mt-lg-5 pt-lg-5">
        <div class="col-sm-12 col-lg-3 mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-blue mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
            The  Management Team
          </h1>
        </div>
        <div class="col-sm-12 col-lg-9 mb-md-0 ">
          <div class="row ">

            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/kelechi.jpg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Mr. Kelechi Dozie
                  </h2>
                  <h5>Chief Executive Officer</h5>
                  <p>
                    Kelechi is a technology enthusiast and a specialist in electronic payments solutions. Kelechi graduated with a B.Sc. in Finance from the University of North Carolina before joining Standard Chartered Bank in Lagos where he oversaw the collection and processing of VAT payments.  Kelechi also had a brief stint with Diamond Bank Plc. before joining Smartswitch Nigeria Ltd, Lagos as its Chief Marketing Officer. He is currently the Chief Vision Officer of I-One-C Limited, a process automation solutions provider and our Chief Executive Officer.
                  </p>
                </div>
              </article>
            </div>
            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image bg-white text-center" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/tolu.png" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Mrs. Tolulope Olajumoke
                  </h2>
                  <h5>Chief Operating Officer</h5>
                  <p>
                    Tolu is a certified program manager with 10 + years of her experience focused on utilising software applications to automate business processes. She has facilitated and managed projects on behalf of public and private sector clients, providing revenue assurance. She currently oversees the company’s investments and subsidiaries whilst also facilitating expansion activities including corporate alliances and new investments.
                  </p>
                </div>
              </article>
            </div>
            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/ebo.jpg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Chinedu Ebo
                  </h2>
                  <h5>Company Secretary / Chief Administration Officer</h5>
                  <p>
                    Mr. Ebo is a legal practitioner. He is an alumnus of the United States Department of State International Visitors Program on US-Africa Investment and trade. Mr. Ebo has previously been an adviser on legal issues and emerging business trend in Nigeria as well as other emerging markets to many companies and government institutions. He participated in the drafting of the African Development Fund strategic development plan for Nigeria and was previously the Special Adviser to the President of the Manufacturers Association of Nigeria (M.A.N).
                  </p>
                </div>
              </article>
            </div>
            <div class="col-sm-12 col-lg-6 mb-lg-5 mb-md-0 about">
              <article class="post post-medium border-0 pb-0 mb-5">
                <div class="post-image" style="height: 350px; overflow: hidden">
                  <img src="assets/img/controls/team/fola.png" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="">
                </div>
                <div class="post-content blue">
                  <h2 class="font-weight-semibold text-5 line-height-6 mt-3 mb-2">
                    Mrs. Folasade Sonowo
                  </h2>
                  <h5>Senior Accountant</h5>
                  <p>
                    Fola is a chartered accountant with over 10 years’ experience in financial reporting and tax planning. Fola currently oversees the company’s accounting operations and directs our internal and external tax audits to ensure compliance.
                  </p>
                </div>
              </article>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
