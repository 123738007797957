import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {TeamComponent} from "../team/team.component";
import {ContactComponent} from "../contact/contact.component";

const routes: Routes = [
  {path: '', component: HomeComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'team', component: TeamComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'contact', component: ContactComponent,
    runGuardsAndResolvers: 'always'}
  ];


@NgModule({
  imports: [
    // RouterModule.forRoot(routes, {enableTracing: true, onSameUrlNavigation: 'reload'}), CommonModule
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
  declarations: []
})

export class RoutingModule { }

